import 'bootstrap/dist/css/bootstrap.min.css';

import { Routes, Route } from 'react-router-dom';

import FormDiff from './FormDiff';
import Login from './Login';
import NoMatch from './NoMatch';
import Layout from './Layout';

function App() {
	return (
		<Routes>
			<Route path="/" element={<Layout />}>
				<Route index element={<FormDiff />} />
				<Route path="login" element={<Login />} />
				<Route path="share/:id" element={<FormDiff />} />
				<Route path="*" element={<NoMatch />} />
			</Route>
		</Routes>
	);
}

export default App;
