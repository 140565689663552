import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import App from './App';

// Get the container.
const container = document.getElementById('root');

// Create a root.
const root = ReactDOM.createRoot(container);

// Initial render.
root.render(
	<React.StrictMode>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</React.StrictMode>
);
