import { Outlet } from 'react-router-dom';
import { ThemeProvider, Container, Row, Col, Navbar } from 'react-bootstrap';

function Layout() {
	return (
		<ThemeProvider
			breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}
		>
			<Navbar bg="dark" variant="dark">
				<Container>
					<Navbar.Brand>
						<img
							alt=""
							src="https://images.evetech.net/corporations/98335809/logo"
							width="30"
							height="30"
							className="d-inline-block align-top"
						/>{' '}
						The Northerners Fitting Compare
					</Navbar.Brand>
					<Navbar.Toggle aria-controls="basic-navbar-nav" />
					{/* <LinkContainer to="login">
						<Button className="d-flex" variant="secondary">
							Login
						</Button>
					</LinkContainer> */}
				</Container>
			</Navbar>
			<Outlet />
			<Container className="mt-5 text-center">
				<Row>
					<Col>
						<p className="mb-1">
							Built by{' '}
							<a
								href="https://evewho.com/character/93546508"
								alt="Weyland Sunji"
								target="_blank"
								rel="noreferrer"
							>
								Weyland Sunji
							</a>{' '}
							as part of the{' '}
							<a
								href="https://evewho.com/corporation/98335809"
								alt="The Northerners"
								target="_blank"
								rel="noreferrer"
							>
								The Northerners
							</a>{' '}
							for the{' '}
							<a
								href="https://evewho.com/alliance/99005338"
								alt="Pandemic Horde"
								target="_blank"
								rel="noreferrer"
							>
								Pandemic Horde
							</a>{' '}
							alliance.
						</p>
						<p>
							Powered by{' '}
							<a
								href="https://janice.e-351.com/about"
								alt="Janice"
								target="_blank"
								rel="noreferrer"
							>
								Janice
							</a>{' '}
							and special thanks to{' '}
							<a
								href="https://evewho.com/character/1399433256"
								alt="Eris Kirke"
								target="_blank"
								rel="noreferrer"
							>
								Eris Kirke
							</a>{' '}
							and Mooncake Industrial.
						</p>
					</Col>
				</Row>
			</Container>
		</ThemeProvider>
	);
}

export default Layout;
