import { Col, Container, Row, Button } from 'react-bootstrap';

function Login() {
	return (
		<Container>
			<Row className="mt-3">
				<Col>
					<p>
						Authenticating with this application will allow you to
						unlock additional features.
					</p>
					<Button variant="primary">Login with EVE Online</Button>
				</Col>
			</Row>
		</Container>
	);
}

export default Login;
